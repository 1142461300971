import Zero from '../../assets/images/0by10.svg';
import One from '../../assets/images/1by10.svg';
import Two from '../../assets/images/2by10.svg';
import Three from '../../assets/images/3by10.svg';
import Four from '../../assets/images/4by10.svg';
import Five from '../../assets/images/5by10.svg';
import Six from '../../assets/images/6by10.svg';
import Seven from '../../assets/images/7by10.svg';
import Eight from '../../assets/images/8by10.svg';
import Nine from '../../assets/images/9by10.svg';
import Ten from '../../assets/images/10by10.svg';

import ZeroBlue from '../../assets/images/0Blue.svg';
import OneBlue from '../../assets/images/1Blue.svg';
import TwoBlue from '../../assets/images/2Blue.svg';
import ThreeBlue from '../../assets/images/3Blue.svg';
import FourBlue from '../../assets/images/4Blue.svg';
import FiveBlue from '../../assets/images/5Blue.svg';
import SixBlue from '../../assets/images/6Blue.svg';
import SevenBlue from '../../assets/images/7Blue.svg';
import EightBlue from '../../assets/images/8Blue.svg';
import NineBlue from '../../assets/images/9Blue.svg';
import TenBlue from '../../assets/images/10Blue.svg';

const images = [Zero, One, Two, Three, Four, Five, Six, Seven, Eight, Nine, Ten];
const imagesBlue = [
    ZeroBlue,
    OneBlue,
    TwoBlue,
    ThreeBlue,
    FourBlue,
    FiveBlue,
    SixBlue,
    SevenBlue,
    EightBlue,
    NineBlue,
    TenBlue,
];

export function CircularProgressBarPage({ rating, candidateDrop, forIncServe, version }) {
    return (
        <div className="CircularProgressbarDiv">
            <img src={forIncServe && version === 1 ? imagesBlue[rating] : images[rating]} alt={`Rating: ${rating}`} />
            {candidateDrop && <p className="feedback-status">Drop-off</p>}
        </div>
    );
}

import React from 'react';
import line1b from '../../assets/images/line1b.svg';
import line2b from '../../assets/images/line2b.svg';
import line3b from '../../assets/images/line3b.svg';
import { getSoftRating } from './Helpers';
import { MultipleSeriesPieChart } from './MultipleSeriesPieChart';
export const AnalyticsSkills = ({ data, type, version, forIncServe }) => {
    let dataNumbers = [];
    data.map((itm) => dataNumbers.push([parseInt(itm.rating) * 10, 100 - parseInt(itm.rating) * 10]));
    return (
        <div className={type === 2 ? 'phychological-skills-wrapperv2' : 'phychological-skills-wrapper'}>
            <div className={type === 2 ? 'phychological-skills-divv2' : 'phychological-skills-div'}>
                <span className={type === 2 ? 'phychological-skills-spanv2' : 'phychological-skills-span'}>
                    Analytical Skills
                </span>
            </div>
            <div className="soft-skills-graph-wrapper">
                <div className="soft-skills" style={{ position: 'relative', display: 'flex' }}>
                    <div className="chart-wrapper">
                        <MultipleSeriesPieChart
                            dataNumbers={dataNumbers}
                            backGroundColor={['rgba(17, 178, 184, 0.8)', '#57bdc517']}
                        />
                    </div>
                    <div>
                        <img className="m1-img" src={line1b}></img>
                        <img className="m2-img" src={line2b}></img>
                        <img className="m3-img" src={line3b}></img>
                    </div>
                    <div className={type === 2 ? 'rating-div2' : 'rating-div'}>
                        {data &&
                            data.map((itm, key) => (
                                <div className="marking-rating-name" key={key}>
                                    <span>{itm.name}</span>
                                    <p style={{ margin: '0px' }}>
                                        <span
                                            className={`marking-rating-span ${
                                                forIncServe && version === 1
                                                    ? 'marking-rating-span'
                                                    : getSoftRating(itm.name, parseInt(itm.rating)).color
                                            }`}
                                        >
                                            {getSoftRating(itm.name, parseInt(itm.rating)).value}
                                        </span>
                                    </p>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
